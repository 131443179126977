<template>
  <div>
    <div class="p-b-10">
      <h4>{{curWorkday}} 作业令详情</h4>
    </div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="3">
        <Select
          :clearable="false"
          size="small"
          v-model="planType"
          @on-change="handleChangeActionType"
        >
          <Option
            v-for="item in planTypeArray"
            :key="'planType_'+item.value"
            :value="item.value"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.assetId"
          clearable
          size="small"
          placeholder="选择线路"
          @on-change="handleChangeAsset"
        >
          <Option
            v-for="item in assetArray"
            :key="'assetId_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.stationId"
          clearable
          size="small"
          placeholder="选择站点"
        >
          <Option
            v-for="item in stationArray"
            :key="'stationId_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.executeUserId"
          palceholder="选择施工负责人"
          size="small"
          clearable
        >
          <Option
            v-for="item in workerArray"
            :key="'userId_'+item.userId"
            :value="item.userId"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="6">
        <i-input
          size="small"
          placeholder="备注"
          v-model='query.keyword'
        ></i-input>
      </i-col>
      <i-col span="4">
        <Button
          size="small"
          icon="ios-search"
          type="primary"
          class="m-r-5"
          @click="handleSearch"
        >搜索</Button>

      </i-col>
    </Row>
    <Row
      :gutter="8"
      class="m-b-5"
    >
      <i-col span="12">
        <Button
          type="error"
          size="small"
          :disabled="selectedIds.length===0"
          @click="handleBatchDelete"
        >批量删除</Button>
      </i-col>
      <i-col span="12">
        <div class="text-right">
          <Page
            size="small"
            :total="tableData.totalRow"
            :page-size="query.pageSize"
            show-total
            :current="query.pageNumber"
            @on-change="changePage"
          ></Page>
        </div>
      </i-col>
    </Row>
    <div>
      <Table
        stripe
        max-height="420"
        size="small"
        :data="tableData.list"
        :columns="tableColumns"
        @on-selection-change="handleChangeSelection"
      ></Table>
      <div class="paging_style">
        <Page
          size="small"
          :total="tableData.totalRow"
          :page-size="query.pageSize"
          show-total
          show-elevator
          :current="query.pageNumber"
          @on-change="changePage"
        ></Page>
      </div>
    </div>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { deleteWorkday } from '@/api/msp/workday'
export default {
  mixins: [sysMixins],
  data () {
    return {
      query: {
        pageNumber: 1,
        pageSize: 15,
        workday: '',
        assetId: null,
        dayTypes: JSON.stringify([1, 2]),
        executeUserId: null,
        stationId: null,
        workdayId: null,
        keyword: ''
      },
      stationArray: [],
      planType: 1,
      planTypeArray: [
        { value: 1, name: '作业计划' },
        { value: 2, name: '行车计划' }
      ],
      selectedIds: [],
      tableColumns: [
        { type: 'selection', width: 60, align: 'center' },
        {
          title: '作业令名称',
          align: 'center',
          key: 'workdayName',
          render: (h, params) => {
            return h('span', params.row.workdayName || '/')
          }
        },
        { title: '线路', align: 'center', key: 'assetName' },
        { title: '站点', align: 'center', key: 'stationName' },
        {
          title: '作业起止时间',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('p', `开始时间：${params.row.startTime}`),
              h('p', `结束时间：${params.row.endTime}`)
            ])
          }
        },
        { title: '作业类型', align: 'center', key: 'dayTypeName' },
        { title: '施工负责人', align: 'center', key: 'executeUserName' },
        { title: '备注', align: 'center', key: 'remark' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            if (new Date(this.curWorkday + ' 18:00:00').valueOf() > new Date().valueOf()) {
              return h('div', [
                h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.handleEdit(params.row)
                    }
                  }
                }, '编辑'),
                h('a', {
                  style: { color: '#ef4f4f' },
                  on: {
                    click: () => {
                      this.handleDelete(params.row)
                    }
                  }
                }, '删除')
              ])
            } else {
              return h('span', '-')
            }
          }
        }
      ]
    }
  },
  computed: {
    tableData: {
      get () {
        const that = this
        const result = that.$store.state.actionDate.tableData

        if (result && result.list) {
          result.list.forEach(item => {
            item._disabled = true
            if (new Date(that.curWorkday + ' 18:00:00').valueOf() > new Date().valueOf()) {
              item._disabled = false
            }
          })
        }
        return result
      },
      set (val) {
        this.$store.commit('set_table_data', val)
      }
    },
    curWorkday: {
      get () {
        return this.$store.state.actionDate.curWorkday
      },
      set (val) {
        this.$store.commit('set_cur_workday', val)
      }
    },
    curDaytypes () {
      return this.$store.state.actionDate.curDaytypes
    },
    assetArray () {
      return this.$store.state.actionDate.assetArray
    },
    allStationArray () {
      return this.$store.state.actionDate.allStationArray
    },
    workerArray () {
      return this.$store.state.actionDate.workerArray
    }
  },
  destroyed () {
    this.curWorkday = ''
    this.tableData = {}
    this.$store.commit('set_work_task_info', {})
    this.$store.commit('set_operate', 1)
  },
  methods: {
    initData () {
      this.selectedIds = []
      this.query.workday = this.curWorkday
      this.$store.dispatch('getActionDateDetailData', this.query)
    },
    handleChangeAsset () {
      this.query.stationId = null
      this.stationArray = this.query.assetId ? this.allStationArray.filter(x => x.assetId === this.query.assetId) : []
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    handleChangeActionType () {
      this.query.dayTypes = this.planType === 2 ? JSON.stringify([3]) : JSON.stringify([1, 2])
      this.query.pageNumber = 1
      this.initData()
    },
    handleChangeSelection (selections) {
      this.selectedIds = selections.map(x => x.id)
    },
    handleEdit (params) {
      this.$store.commit('set_operate', 2)
      this.$store.commit('set_work_task_info', params)
      this.setShowLeftBlock(true)
      this.setLeftComponent('editComponent')
    },
    handleDelete (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除该条数据？',
        onOk: () => {
          const postData = {
            workday: this.curWorkday,
            dayTypes: params.dayType,
            workdayId: params.id
          }
          deleteWorkday(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.initData()
            }
          })
        }
      })
    },
    handleBatchDelete () {
      if (this.selectedIds.length === 0) {
        this.$Notice.error({ desc: '非法操作' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除已选择的数据？',
        onOk: () => {
          const postData = {
            workday: this.curWorkday,
            workdayIds: JSON.stringify(this.selectedIds)
          }
          deleteWorkday(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.initData()
            }
          })
        }
      })
    }
  },
  watch: {
    // curWorkday: {
    //   deep: true,
    //   immediate: true,
    //   handler (val) {
    //     if (val) {
    //       this.initData()
    //     }
    //   }
    // },
    curDaytypes: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          if (val.includes(1) || val.includes(2)) {
            this.planType = 1
            this.query.dayTypes = JSON.stringify([1, 2])
          } else {
            this.planType = 2
            this.query.dayTypes = JSON.stringify([3])
          }
          this.initData()
        }
      }
    }
  }
}
</script>
